import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ITreeRooms } from 'interfaces';
import { roomActions } from 'store/slices/room';

import {
  allRoomsHideController,
  getWordDayForm,
  roomHideController,
} from 'utils';

import { Dropdown } from './Dropdown';
import {
  updateHiddenClansParams,
  updateHiddenRoomsParams,
  updateSearchParams,
} from './utils';

interface ListClansProps {
  activeClans: ITreeRooms[];
  inactiveClans: ITreeRooms[];
  treeRooms: ITreeRooms[];
  isAllActiveStreamsHidden: boolean;
  isAllInactiveStreamsHidden: boolean;
  onAllActiveStreamsHide: (value: boolean) => void;
  onAllInactiveStreamsHide: (value: boolean) => void;
}

export const ListClans: FC<ListClansProps> = ({
  activeClans,
  inactiveClans,
  treeRooms,
  isAllActiveStreamsHidden,
  isAllInactiveStreamsHidden,
  onAllActiveStreamsHide,
  onAllInactiveStreamsHide,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const departmentWordForm = getWordDayForm(activeClans?.length, [
    'подразделение',
    'подразделения',
    'подразделений',
  ]);

  const handleAllActiveStreamsClick = () => {
    const updatedTreeRooms = allRoomsHideController(
      treeRooms,
      activeClans,
      !isAllActiveStreamsHidden
    );

    const showClans = updatedTreeRooms.flatMap(({ isHidden, clanId }) =>
      !isHidden ? { clanId } : []
    );

    const newSearchParams = updateSearchParams(
      searchParams,
      showClans,
      isAllActiveStreamsHidden,
      isAllInactiveStreamsHidden
    );

    setSearchParams(newSearchParams);

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    onAllActiveStreamsHide(!isAllActiveStreamsHidden);
  };

  const handleAllInactiveStreamsClick = () => {
    const updatedTreeRooms = allRoomsHideController(
      treeRooms,
      inactiveClans,
      !isAllInactiveStreamsHidden
    );

    const showClans = updatedTreeRooms.flatMap(({ isHidden, clanId }) =>
      !isHidden ? { clanId } : []
    );

    const newSearchParams = updateSearchParams(
      searchParams,
      showClans,
      isAllInactiveStreamsHidden,
      isAllActiveStreamsHidden
    );

    setSearchParams(newSearchParams);

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    onAllInactiveStreamsHide(!isAllInactiveStreamsHidden);
  };

  const handleRoomHide = (livekitName: string, clanId: string) => {
    const updatedTreeRooms = roomHideController(treeRooms, clanId, livekitName);

    dispatch(roomActions.updateTreeRooms(updatedTreeRooms));

    const newParams = updateHiddenRoomsParams(
      treeRooms,
      searchParams,
      updatedTreeRooms
    );

    setSearchParams(newParams);
  };

  const handleClanHide = (clanId?: string) => {
    if (clanId) {
      let currentRooms: ITreeRooms[] = treeRooms;

      const selectedClan = currentRooms.find((room) => room.clanId === clanId);

      if (selectedClan?.rooms.length) {
        selectedClan?.rooms.forEach(({ livekitName }) => {
          const updatedTreeRooms = roomHideController(
            currentRooms,
            clanId,
            livekitName,
            !selectedClan.isHidden
          );

          if (updatedTreeRooms) {
            currentRooms = updatedTreeRooms;

            dispatch(roomActions.updateTreeRooms(currentRooms));
          }
        });
      } else {
        const updatedRooms = currentRooms.map((room) =>
          room.clanId === selectedClan?.clanId
            ? { ...room, isHidden: !room.isHidden }
            : room
        );

        currentRooms = updatedRooms;

        dispatch(roomActions.updateTreeRooms(updatedRooms));
      }

      const newParams = updateHiddenClansParams(
        searchParams,
        clanId,
        treeRooms,
        currentRooms
      );

      setSearchParams(newParams);
    }
  };

  return (
    <div className="flex flex-col gap-[4px]">
      {!!activeClans.length && (
        <Dropdown
          label={`${activeClans.length} ${departmentWordForm} ведут эфиры`}
          isActive={!isAllActiveStreamsHidden}
          onParentClick={handleAllActiveStreamsClick}
        >
          {activeClans.map(({ rooms, clanName, clanId, isHidden }) => (
            <div key={clanId} className="pl-[18px]">
              <Dropdown
                id={clanId}
                rooms={rooms}
                label={clanName}
                isActive={!isHidden}
                onChildClick={handleRoomHide}
                onParentClick={handleClanHide}
              />
            </div>
          ))}
        </Dropdown>
      )}
      {!!inactiveClans.length && (
        <Dropdown
          label={`${inactiveClans.length} не ведут эфир`}
          isActive={!isAllInactiveStreamsHidden}
          onParentClick={handleAllInactiveStreamsClick}
        >
          {inactiveClans.map(({ rooms, clanName, clanId, isHidden }) => (
            <div key={clanId} className="pl-[18px]">
              <Dropdown
                id={clanId}
                rooms={rooms}
                label={clanName}
                isActive={!isHidden}
                onChildClick={handleRoomHide}
                onParentClick={handleClanHide}
              />
            </div>
          ))}
        </Dropdown>
      )}
    </div>
  );
};
