import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { IRoom } from 'interfaces/room';

interface DropdownProps {
  label: string;
  isActive: boolean;
  id?: string;
  rooms?: IRoom[];
  children?: ReactNode;
  onParentClick?: (id?: string) => void;
  onChildClick?: (title: string, id: string) => void;
}

export const Dropdown: FC<DropdownProps> = ({
  label,
  isActive,
  onParentClick,
  onChildClick,
  id,
  rooms,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleArrowClick = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="flex flex-col gap-[4px]">
      <div className="flex items-center ml-[6px] cursor-pointer">
        <Down
          onClick={handleArrowClick}
          className={cn('[&>path]:fill-tpg_base [&>path]:transition', {
            'rotate-[270deg]': !isOpen,
            '[&>path]:!fill-bright_product': isActive,
          })}
        />
        <span
          className={cn('tpg-c2 text-bright_product transition', {
            '!text-tpg_base': !isActive,
          })}
          onClick={() => onParentClick?.(id)}
        >
          {label}
        </span>
      </div>
      {isOpen &&
        (rooms
          ? rooms.map(
              ({ publisherName, isHidden: isHiddenRoom, livekitName }) => (
                <span
                  key={publisherName}
                  onClick={() => id && onChildClick?.(livekitName, id)}
                  className={cn(
                    'cursor-pointer ml-[32px] tpg-c2 text-bright_product',
                    {
                      'text-tpg_base': isHiddenRoom,
                    }
                  )}
                >
                  {publisherName}
                </span>
              )
            )
          : children)}
    </div>
  );
};
