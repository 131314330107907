import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  HttpStatusCode,
} from 'axios';
import { ELStorageKeys } from 'constants/localStorage';
import { authRoutes } from 'constants/routes';

import { getToken } from 'utils';
import {
  clearLStorageMany,
  getLStorage,
  setLStorage,
} from 'utils/helpers/localStorage';

const { REACT_APP_API_URL, NODE_ENV, REACT_APP_DEX_SECRET } = process.env;

export const __DEV__ = NODE_ENV === 'development';

export const baseURL = REACT_APP_API_URL;

const instance = axios.create({
  baseURL,
});

let isRefreshing = false;

const responseSuccessHandler = (response: AxiosResponse) => response;

const responseErrorHandler = async (error: AxiosError) => {
  const originalRequest = error.config as AxiosRequestConfig & {
    _retry?: boolean;
  };

  if (error.response?.status === HttpStatusCode.Gone) {
    clearLStorageMany(...Object.values(ELStorageKeys));

    window.location.replace(authRoutes.SIGN_IN);

    return Promise.reject(error);
  }

  if (error.response?.status === HttpStatusCode.Unauthorized) {
    originalRequest._retry = true;

    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const { data } = await axios.post(`${baseURL}/token`, {
          refresh_token: getLStorage(ELStorageKeys.refreshToken),
          client_secret: REACT_APP_DEX_SECRET,
        });

        setLStorage([ELStorageKeys.authToken, data.token]);
        setLStorage([ELStorageKeys.refreshToken, data.refresh_token]);

        instance.defaults.headers['Authorization'] = `Bearer ${data.token}`;

        instance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${data.token}`;

        isRefreshing = false;
      } catch (error) {
        isRefreshing = false;

        if (
          error instanceof AxiosError &&
          error.response?.status === HttpStatusCode.Unauthorized
        ) {
          clearLStorageMany(...Object.values(ELStorageKeys));

          window.location.replace(authRoutes.SIGN_IN);
        }

        return Promise.reject(error);
      }
    }

    return instance(originalRequest);
  }

  return Promise.reject(error);
};

instance.interceptors.request.use(
  (config) => {
    const authToken = getToken();

    if (!authToken) {
      delete config.headers['Authorization'];
    }

    if (authToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
      } as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export const createLoadSource = () => axios.CancelToken.source();

export default instance;
